
import Vue from 'vue'
import { setCookie } from '../services/cookie.service'
import { CsBtn } from '@consteel/csuetify'

export default Vue.extend({
  name: 'ChangeThemeButton',
  components: {
    CsBtn,
  },
  computed: {
    appIsDark(): boolean {
      return this.$vuetify.theme.dark
    },
  },
  methods: {
    changeTheme: function (): void {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      setCookie('dark', this.$vuetify.theme.dark, 365)
    },
  },
})
