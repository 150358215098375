var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.currentUser
    ? _c(
        "v-menu",
        {
          attrs: { bottom: "", "close-on-content-click": "", "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on: onMenu, attrs: attrsMenu }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({
                                attrs: attrsTooltip,
                                on: onTooltip,
                              }) {
                                return [
                                  _c(
                                    "cs-avatar",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            email: _vm.currentUser.email,
                                            size: "38",
                                          },
                                        },
                                        "cs-avatar",
                                        { ...attrsMenu, ...attrsTooltip },
                                        false
                                      ),
                                      { ...onMenu, ...onTooltip }
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("Profile")))])]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            1130881427
          ),
        },
        [
          _c(
            "v-list",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-subheader",
                { staticClass: "mb-2" },
                [
                  _c("cs-avatar", {
                    attrs: { email: _vm.currentUser.email, size: "38" },
                  }),
                  _c(
                    "cs-flex",
                    { staticClass: "menu-header", attrs: { vertical: "" } },
                    [
                      _c("span", { staticClass: "menu-header-name" }, [
                        _vm._v(_vm._s(_vm.displayName)),
                      ]),
                      _c("span", { staticClass: "menu-header-email" }, [
                        _vm._v(_vm._s(_vm.currentUser.email)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _vm._l(_vm.menuItems, function (item, index) {
                return _c(
                  "v-list-item",
                  {
                    key: index,
                    staticStyle: { cursor: "pointer" },
                    on: { click: item.action },
                  },
                  [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }