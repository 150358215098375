
import { CsAvatar, CsFlex } from '@consteel/csuetify'
import { getAuth, User } from '@firebase/auth'
import Vue from 'vue'
import { Route } from 'vue-router'
import { logout } from '@/modules/auth'

type MenuItem = {
  text: string
  action: () => Promise<void> | Promise<Route>
}

export default Vue.extend({
  name: 'ToolbarMenu',
  components: {
    CsAvatar,
    CsFlex,
  },
  data() {
    return {
      menuItems: [] as MenuItem[],
    }
  },
  computed: {
    currentUser(): User | null {
      return getAuth().currentUser
    },
    appIsDark(): boolean {
      return this.$vuetify.theme.dark
    },
    displayName(): string {
      if (!this.currentUser?.displayName) return ''

      return this.currentUser.displayName
    },
  },
  methods: {
    logout,
  },
  created() {
    this.menuItems.push({
      text: this.$t('Account') as string,
      action: () => this.$router.push('/account'),
    })
    this.menuItems.push({
      text: this.$t('Log out') as string,
      action: () => this.logout(),
    })
  },
})
