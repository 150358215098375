var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-btn",
    {
      staticClass: "ml-auto",
      attrs: { icon: "", "min-width": "0" },
      on: { click: _vm.changeTheme },
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ attrs, on }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: _vm.appIsDark ? "yellow" : "primary",
                          },
                        },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.appIsDark
                              ? "mdi-weather-sunny"
                              : "mdi-weather-night"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.appIsDark
            ? _c("span", [_vm._v(_vm._s(_vm.$t("Change to light mode")))])
            : _c("span", [_vm._v(_vm._s(_vm.$t("Change to dark mode")))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }